import React from 'react'
import { graphql, navigate } from 'gatsby';
import { Translation } from 'react-i18next';
import { withI18next } from '@wapps/gatsby-plugin-i18next';
import Layout from 'layouts/index'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {getLanguage, getGroupTypes} from 'store/modules/request';

import {markdown} from 'utils';

import moment from 'moment';

import analytics from 'utils/analytics'

class RequestSuccessPage extends React.Component {
    state = {}

    componentDidMount(){
        const {actualRequest, groupTypes} = this.props;
        if(actualRequest){
            analytics({
               "eventName":"bookFinished",
               "dateBook": actualRequest.date.replace(new RegExp('-', 'g'), '|'),
               "hourBook": actualRequest.hour,
               "groupType":"Grupo",
               "assistantsNumber": actualRequest.attendees,
               "language": actualRequest.visit_language
            })
        }
        if(groupTypes === undefined) this.props.getGroupTypes(this.props.pageContext.lng);
    }

    static getDerivedStateFromProps(props, state){
        const {actualRequest} = props;
        if (!actualRequest && typeof window !== 'undefined') navigate("/");

        return null;
    }

    anotherRequest(){
        analytics({"eventName": "startBookAgain"})
        navigate("/request");
    }

    render() {
        const {actualRequest, groupTypes, data} = this.props;
        const {groupsList: group} = data;

        if(actualRequest === undefined) return null;
        if(groupTypes === undefined) return null;

        let dateTime = moment(actualRequest.date + " " + actualRequest.hour);

        return (<Translation>{t => (
            <Layout name={'requestSuccessPage'} title={t('requestAcceptedTitle')} {...this.props}>
                <h4>{t('seatFactoryTours')}</h4>
                <div className="content-block container-fuild">
                    <div className="row">
                        <div className="content-block-content col-xs-12 col-md-6 content-block-info">
                            <h2 className="no-margin-top">{t('requestAcceptedTitle')}</h2>
                            <div dangerouslySetInnerHTML={markdown(t('requestAcceptedText'))}></div>
                            <div className="actions to-bottom">
                                <div onClick={this.anotherRequest.bind(this)} className="btn btn-accept with-margin-top-small">{t('askAnotherRequest')}</div>
                            </div>
                        </div>
                        <div className="content-block-sidebar col-md-6">
                            <div className="box">
                                <div className="box-content">
                                    <div className="box-footer error">
                                        <h4 className="no-margin-bottom">
                                            {t("requestNumber")}<span className="pull-right right-info">{actualRequest.ref}</span>
                                        </h4>
                                        <h6 className="pending">
                                            {t('doneAt')}{moment(actualRequest.created_at).format('DD/MM/YYYY')}
                                            <span className="pull-right right-info">
                                                {t("pendingConfirmation")}
                                            </span>
                                        </h6>
                                    </div>
                                    <div className="row">
                                        <div className="box-content-block col-xs-12 col-md-6">
                                            <h4>{t('date')}</h4>
                                            <p>{dateTime.format('LL')}<br />{dateTime.format('HH:mm')}h</p>
                                        </div>
                                        <div className="box-content-block col-xs-12 col-md-6">
                                            <h4>{t('group')}</h4>
                                            <p>
                                                {actualRequest.group_name}<br />{groupTypes.find(g => g.id === actualRequest.group_type_id).name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="box-content-block col-xs-12 col-md-6">
                                            <h4>{t('attendees')}</h4>
                                            <p>{actualRequest.attendees} {actualRequest.attendees === 1
                                                ? t('person')
                                                : t('people')}
                                            </p>
                                        </div>
                                        <div className="box-content-block col-xs-12 col-md-6">
                                            <h4>{t("language")}</h4>
                                            <p>
                                                {t(this.props.getLanguage(actualRequest.visit_language))}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="text-point">{group.additional_information}</p>
                                    <p className="text-point">{t('requestInformation1')}</p>
                                    <p className="text-point">{t('requestInformation2')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )}</Translation>);
    }
}

const mapStateToProps = state => ({
    actualRequest: state.request.actualRequest,
    groupTypes: state.request.groupTypes
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getLanguage,
    getGroupTypes
}, dispatch)

export const pageQuery = graphql `
  query($lng: String!){
  groupsList(_id: { eq: 2 },_lang: { eq: $lng }) {
    slug
    additional_information
  },
  locales: allLocale(filter: { lng: { eq:$lng }, ns: { eq: "messages" } }) {
    ...LocaleFragment
  }
  site {
    siteMetadata {
      siteTitle
      activeEnv
      analytics
    }
  }
}
`

export default connect(mapStateToProps, mapDispatchToProps)(withI18next()(RequestSuccessPage))
